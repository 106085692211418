import Image from 'next/image';
import { TabPanel } from 'react-tabs';

import { Container } from '@/components/core/flexbox';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import CustomTab from './CustomTab';
import CustomTabList from './CustomTabList';
import CustomTabs from './CustomTabs';
import MobileTabsSlider from './MobileTabsSlider';
import PanelList from './PanelList';

const Tabs = ({ tabs, reverse, hideDescriptionInMobile, keepMultiLineStyles }) => {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!tabs || !tabs.length) return null;

  return (
    <>
      {isMobile ? (
        <MobileTabsSlider content={tabs} />
      ) : (
        <Container data-testid="container">
          <CustomTabs reverse={reverse} focusTabOnClick={false}>
            <CustomTabList reverse={reverse}>
              {tabs.map((item) => (
                <CustomTab
                  key={item.id}
                  $singleLine={!item.description}
                  $keepMultiLineStyles={keepMultiLineStyles}
                  $hideDescriptionInMobile={hideDescriptionInMobile}
                >
                  <h3>{item?.title || item?.label}</h3>
                  {item.description && <p>{item.description}</p>}
                </CustomTab>
              ))}
            </CustomTabList>

            <PanelList reverse={reverse}>
              {tabs.map((item) => (
                <TabPanel key={item.id} data-testid="tabPanel-item">
                  <Image
                    src={item.image?.data?.attributes?.url}
                    alt={item.image?.data?.attributes?.alternativeText || ''}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 991px) 40vw, 620px"
                    objectFit="contain"
                  />
                </TabPanel>
              ))}
            </PanelList>
          </CustomTabs>
        </Container>
      )}
    </>
  );
};

export default Tabs;

import styled from 'styled-components';

const ConditionList = styled.div`
  position: relative;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 21px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: block;
    margin-left: -24px;
    margin-right: -24px;
  }
`;

export default ConditionList;

import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';

export const Wrapper = styled.section`
  overflow-x: clip;
  background-color: ${(props) => props.theme.colors.neutral.default};
  padding-top: 227px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
  }
`;

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 876.81px;
  height: 876.81px;
  margin-top: -227px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 626px;
    height: 626px;
    margin: 0 0 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    overflow-x: hidden;
    width: 432px;
    height: 432px;
    margin: -15px 0 8px;
    z-index: 0;
  }
`;

export const Content = styled.div`
  max-width: 402px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 456px;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    z-index: 1;
  }

  .container {
    padding: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) and (min-width: ${(props) =>
        props.theme.breakpoints.min_md}px) {
      justify-items: center;
      text-align: center;
    }
  }
`;

import Image from 'next/image';
import { Section } from '@/components/core';
import Metrics from './Metrics';
import { Wrapper, CustomContainer, ImageWrapper, Content } from './TheWhyStyles';
import getImgSrcWorkaround from '@/utils/images';
import { Intro } from '@/components/shared';

const TheWhy = ({ content }) => {
  if (!content) return null;

  const { image, metrics, upperLabel, title, subtitle } = content;
  return (
    <Section>
      <Wrapper>
        <CustomContainer>
          <ImageWrapper>
            <Image
              src={getImgSrcWorkaround(image?.data?.attributes.url)}
              alt={image.data.attributes.alternativeText || ''}
              fill
              sizes="(max-width: 991px) 100vw, 644px"
            />
          </ImageWrapper>
          <Content>
            <Intro
              upperLabel={upperLabel}
              title={title}
              description={subtitle}
              align="left"
              innerSpacer
            />
            <Metrics content={metrics} />
          </Content>
        </CustomContainer>
      </Wrapper>
    </Section>
  );
};

export default TheWhy;

import Box from './Box';
import Content from './Content';
import ImageBox from './Image';
import Shape from './Shape';
import { StyledContainer } from './TestimonialStyles';

const Testimonial = ({ backgroundShape, content, colored, left, imagePosition, small }) => {
  if (!content) return null;
  const { quote, job, name, image } = content;

  return (
    <StyledContainer>
      {backgroundShape && <Shape />}
      <Box $left={left} $small={small}>
        <Content quote={quote} job={job} name={name} colored={colored} />
        <ImageBox image={image} imagePosition={imagePosition} />
      </Box>
    </StyledContainer>
  );
};

export default Testimonial;

import Image from 'next/image';
import styled from 'styled-components';

import getImgSrcWorkaround from '@/utils/images';

const Condition = ({ content, version = 'desktop' }) => {
  const { image, name } = content;
  return (
    <Wrapper data-testid={`${version}Card`}>
      <Image
        src={getImgSrcWorkaround(image.data.attributes.url)}
        alt={image.data.attributes.alternativeText || ''}
        width={120}
        height={120}
      />
      <Name>{name}</Name>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  row-gap: 50px;
  border-radius: 8px;
  padding: 24px 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 0 24px;
  }
`;

const Name = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export default Condition;

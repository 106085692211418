'use client';

import { useState } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import Slider from 'react-slick';
import styled from 'styled-components';

import getImgSrcWorkaround from '@/utils/images';

const Wrapper = styled.div`
  position: relative;
  margin-top: 16px;

  & .slick-dots {
    bottom: unset;
    top: 0;
    padding-top: calc(75% + 10px);
    z-index: -1;
  }
  & .slick-dots li {
    width: unset;
    height: unset;
    margin: 0 4px;
  }
  & .slick-dots button {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 2px solid #a4aab6;
    padding: 0;
    border-radius: 100%;
  }
  & .slick-dots button::before {
    content: none;
  }
  & .slick-dots .slick-active button {
    background-color: #a4aab6;
  }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: calc(75% + 40px) 24px 0 24px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const SelectedWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-top: 75%;
  margin: 0 auto;
`;

const SelectedImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;
  right: 24px;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  border-radius: 8px;
  overflow: hidden;

  img {
    object-fit: cover;
  }

  &.active {
    opacity: 1;
    transition: opacity 300ms 150ms ease-in-out;
  }
`;

const ItemTitle = styled.h6`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 8px;
  text-align: center;
`;

const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
  text-align: center;
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: (i) => <button type="button">{i + 1}</button>,
};

function MobileTabsSlider({ content }) {
  const [current, setCurrent] = useState(0);

  if (!content || !content.length) return null;

  const handleChange = (index) => {
    setCurrent(index);
  };

  return (
    <Wrapper data-testid="mobile-tab">
      <ImageWrapper>
        <SelectedWrapper data-testid="mobile-image">
          {content.map((item, index) => (
            <>
              {item.image?.data && (
                <SelectedImage key={item.id} className={cn({ active: current === index })}>
                  <Image
                    src={getImgSrcWorkaround(item.image.data.attributes.url)}
                    alt={item.image.data.attributes.alternativeText || ''}
                    fill
                    sizes="100vw"
                  />
                </SelectedImage>
              )}
            </>
          ))}
        </SelectedWrapper>
      </ImageWrapper>
      <Slider beforeChange={(_, index) => handleChange(index)} {...settings}>
        {content.map((item, index) => (
          <Item key={item.id} order={index}>
            <ItemTitle>{item?.title || item?.label}</ItemTitle>
            {item.description && <ItemDescription>{item.description}</ItemDescription>}
          </Item>
        ))}
      </Slider>
    </Wrapper>
  );
}

export default MobileTabsSlider;

import styled from 'styled-components';

const Shape = styled.div`
  background: ${(props) =>
    `linear-gradient(to top right, ${props.theme.colors.dark_neutral}, ${props.theme.colors.dark_neutral}00)`};
  border-radius: 50%;
  bottom: -125px;
  height: 356px;
  position: absolute;
  right: -110px;
  width: 356px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export default Shape;

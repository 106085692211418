import styled from 'styled-components';
import Image from 'next/image';
import { Container } from '@/components/core/flexbox';
import CircleShape from '../../../../public/assets/shapes/dt_circle.svg';

const ImageGroup = ({ content }) => {
  const { image } = content;

  return (
    <Wrapper>
      <CustomContainer>
        <Content>
          <HeaderShape />
          <FrontImagePosition>
            <Image
              priority
              src={image?.data?.attributes.url}
              alt={image?.data?.attributes.alternativeText}
              fill
              sizes="(max-width: 767px) 271px,
                     (max-width: 991px) 500px,
                     602px"
            />
          </FrontImagePosition>
        </Content>
      </CustomContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const CustomContainer = styled(Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
`;

const FrontImagePosition = styled.div`
  position: absolute;
  width: 602px;
  height: 616px;
  z-index: 1;
  bottom: -189px;
  left: 510px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    width: 500px;
    height: 458px;
    bottom: -272px;
    left: unset;
    right: -60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    width: 271px;
    height: 277px;
    right: -24px;
    top: 40px;
  }
`;

const HeaderShape = styled(CircleShape)`
  position: absolute;
  left: 413px;
  top: -389px;
  width: 963px;
  height: 963px;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    width: 824px;
    height: 824px;
    top: -50px;
    right: -270px;
    left: unset;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    width: 441px;
    height: 441px;
    top: 40px;
    right: -185px;
  }
`;

export default ImageGroup;

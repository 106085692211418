import Slider from 'react-slick';

import { Section } from '@/components/core';
import { Container } from '@/components/core/flexbox';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import Wrapper from './Wrapper';
import ConditionList from './ConditionList';
import Condition from './Condition';
import { Intro } from '@/components/shared';

const sliderSettings = {
  dots: true,
  speed: 500,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const ComprehensiveCare = ({ content }) => {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const { title, description, conditions } = content;
  return (
    <Wrapper>
      <Section>
        <Intro title={title} description={description} alignMobile="left" innerSpacer />
        <Container>
          <ConditionList>
            {isMobile ? (
              <Slider {...sliderSettings}>
                {conditions.map((condition) => (
                  <Condition key={condition.id} content={condition} version="mobile" />
                ))}
              </Slider>
            ) : (
              conditions.map((condition) => (
                <Condition key={condition.id} content={condition} version="desktop" />
              ))
            )}
          </ConditionList>
        </Container>
      </Section>
    </Wrapper>
  );
};

export default ComprehensiveCare;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 40px;
  column-gap: 40px;
  max-width: 422px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    row-gap: 24px;
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`;

export const Container = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;

export const Metric = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  color: ${(props) => props.theme.colors.grey.dark};
  padding-bottom: 4px;

  strong {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding-right: 4px;
  }
  sup {
    a {
      color: ${(props) => props.theme.colors.primary.default};
      font-size: 80%;
      font-weight: 400;
      vertical-align: super;
      cursor: pointer;
    }
  }
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey[800]};
`;

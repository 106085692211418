import styled, { css } from 'styled-components';

const Content = ({ quote, job, name, colored }) => (
  <Wrapper colored={colored}>
    <Quote>{quote}</Quote>
    <Name>{name}</Name>
    <Job>{job}</Job>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 80px 88px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 40px 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;
  }

  ${(props) =>
    props.colored &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
    `};
`;

const Quote = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 24px;
  line-height: 35px;
  position: relative;
  padding-top: 44px;
  margin-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 16px;
    padding-top: 36px;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 20px;
    width: 27px;
    background: url('/assets/icons/quote.svg') no-repeat center center;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey[800]};
`;

const Job = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey[800]};
`;

export default Content;

import Image from 'next/image';
import styled from 'styled-components';

import getImgSrcWorkaround from '@/utils/images';

const ImageBox = ({ image, imagePosition = '50% 20%' }) => (
  <Wrapper $objectPosition={imagePosition}>
    {image && (
      <Image
        src={getImgSrcWorkaround(image?.data?.attributes.url)}
        alt={image?.data?.attributes.alternativeText || ''}
        fill
        sizes="(max-width: 767px) 100vw, 50vw"
      />
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};

  img {
    object-fit: cover;
    object-position: ${({ $objectPosition }) => $objectPosition};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    min-height: 181px;
  }
`;

export default ImageBox;

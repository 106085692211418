import { Section } from '@/components/core';

import Tabs from '@/components/shared/Tabs';
import { Intro } from '@/components/shared';

const TheProduct = ({ content }) => {
  const { productTab, title, description } = content;

  return (
    <Section>
      <Intro title={title} description={description} alignMobile="left" size="sm" innerSpacer />
      <Tabs tabs={productTab} reverse hideDescriptionInMobile />
    </Section>
  );
};

export default TheProduct;

import MarkdownRichText from '@/components/shared/MarkdownRichText';
import { Wrapper, Container, Metric, Description } from './MetricsStyles';

export default function Metrics({ content }) {
  return (
    <Wrapper>
      {content.map(({ metric, description, id }) => (
        <Container key={id}>
          <Metric>
            <MarkdownRichText elements={['a']}>{metric}</MarkdownRichText>
          </Metric>
          <Description>{description}</Description>
        </Container>
      ))}
    </Wrapper>
  );
}

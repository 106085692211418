import styled from 'styled-components';
import Image from 'next/image';

import dotsLine from '@/public/assets/bloom/dots-line.png';

const Step = ({ content, index }) => {
  const { name, description } = content;
  return (
    <Wrapper>
      <NumberWrapper>
        <Number>{index}</Number>
      </NumberWrapper>
      <DotsWrapper>
        <Image src={dotsLine} alt="dots" />
      </DotsWrapper>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NumberWrapper = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: solid 2px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0 20px 50px rgba(239, 233, 230, 0.8);
  background-image: linear-gradient(white, white), linear-gradient(white, transparent);
  border-image-source: linear-gradient(rgb(255, 255, 255) 0%, transparent 100%);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 40px;
    height: 40px;
  }
`;

const Number = styled.div`
  width: 56px;
  height: 56px;
  background: linear-gradient(rgb(247, 244, 242) 0px, white 100%);

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  width: 165px;
  height: 5px;
  top: 26px;
  left: 77px;
  display: flex;

  ${Wrapper}:last-child & {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    display: none;
  }
`;

const Name = styled.p`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 16px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 16px;
  }
`;

export default Step;

import styled, { css } from 'styled-components';

const PanelList = styled.div`
  ${(props) =>
    !props.reverse &&
    css`
      flex: 1 0 auto;
    `};

  ${(props) =>
    props.reverse &&
    css`
      max-width: 620px;
    `};

  width: 55%;
  height: 408px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  img {
    object-fit: cover;
    object-position: center center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    height: 556px;
  }
`;

export default PanelList;

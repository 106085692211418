import Image from 'next/image';
import { Wrapper, CustomContainer, ImageWrapper, Content } from './HolisticHealthStyles';
import getImgSrcWorkaround from '@/utils/images';
import { Intro } from '@/components/shared';

const HolisticHealth = ({ content }) => {
  if (!content) return null;
  const { image, upperLabel, title, subtitle } = content;
  return (
    <Wrapper>
      <CustomContainer>
        <Content>
          <Intro
            upperLabel={upperLabel}
            title={title}
            description={subtitle}
            align="left"
            xs="0"
            sm="0"
            md="0"
          />
        </Content>
        <ImageWrapper>
          <Image
            src={getImgSrcWorkaround(image?.data?.attributes.url)}
            alt={image.data.attributes.alternativeText}
            fill
            sizes="(max-width: 767px) 432px,
                   (max-width: 991px) 626px,
                    876.81px"
          />
        </ImageWrapper>
      </CustomContainer>
    </Wrapper>
  );
};

export default HolisticHealth;

import styled from 'styled-components';

import { Header } from '@/components/core';
import { Section } from '@/components/core';
import Subtitle from '@/components/core/Header/Subtitle';

import ImageGroup from './ImageGroup';

import theme from '@/utils/styles-variables';

const HeaderDT = ({ content }) => {
  return (
    <CustomSection color={theme.colors.neutral.default} md="110" sm="65" xs="90">
      <CustomHeader
        content={{
          ...content,
          href: {
            label: 'Learn more',
            href: content.link,
          },
        }}
      >
        <ImageGroup content={content} />
      </CustomHeader>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  overflow-x: clip;
`;

const CustomHeader = styled(Header)`
  background-image: none;
  height: min-content;
  padding-top: 226px;
  padding-bottom: 222px;

  h1 {
    max-width: 420px;
  }

  ${Subtitle} {
    max-width: 297px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    height: min-content;
    padding-top: 112px;
    padding-bottom: 272px;

    ${Subtitle} {
      max-width: 290px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    padding-top: 88px;
    max-width: 100%;
    h1 {
      width: 200px;
      max-width: 100%;
    }
    ${Subtitle} {
      max-width: 100%;
    }
  }
`;

export default HeaderDT;

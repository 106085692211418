import { Tabs } from 'react-tabs';
import styled, { css } from 'styled-components';

const CustomTabs = styled(Tabs)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `};
`;

export default CustomTabs;

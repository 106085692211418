import { Section } from '@/components/core';
import { Title } from '@/components/core/Title';
import { Container } from '@/components/core/flexbox';
import theme from '@/utils/styles-variables';

import StepList from './StepList';
import Step from './Step';

const HowItWorks = ({ content }) => {
  if (!content || !content?.steps?.length) return null;
  const { title, steps } = content;
  return (
    <Section color={theme.colors.neutral.default}>
      <Container>
        <Title size="2xl">{title}</Title>
        <StepList>
          {steps.map((step, index) => (
            <Step key={step.id} content={step} index={index + 1} />
          ))}
        </StepList>
      </Container>
    </Section>
  );
};

export default HowItWorks;

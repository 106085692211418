import { TabList } from 'react-tabs';
import styled, { css } from 'styled-components';

const CustomTabList = styled(TabList)`
  padding-right: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${(props) =>
    props.reverse &&
    css`
      padding-right: 0;
      padding-left: 55px;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-right: 75px;

    ${(props) =>
      props.reverse &&
      css`
        padding-right: 0;
        padding-left: 106px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-right: 42px;
    ${(props) =>
      props.reverse &&
      css`
        padding-right: 0;
        padding-left: 42px;
      `};
  }
`;

export default CustomTabList;

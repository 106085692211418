import Testimonial from '@/components/shared/Testimonial';
import { Section } from '@/components/core';
import theme from '@/utils/styles-variables';

const TestimonialSection = (props) => (
  <Section lastColored color={theme.colors.neutral.default}>
    <Testimonial {...props} />
  </Section>
);

export default TestimonialSection;

import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  padding-bottom: 136px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 64px;
  }
`;

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column-reverse;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  min-width: 600px;
  width: 644px;

  img {
    border-radius: 8px;
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
    min-width: unset;
    height: 405px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 66.25vw;
  }
`;

export const Content = styled.div`
  max-width: 514px;
  margin-left: 36px;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 0 0 48px;
    max-width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 0 0 40px;
  }

  .container {
    padding: 0;
  }
`;

import styled from 'styled-components';

const StepList = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 32px;
  margin-top: 56px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    grid-auto-flow: unset;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    gap: 40px 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
    gap: 32px;
    margin-top: 16px;
  }
`;

export default StepList;

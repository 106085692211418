import { Tab } from 'react-tabs';
import styled, { css } from 'styled-components';

const CustomTab = styled(Tab)`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  min-height: 48px;
  position: relative;
  max-width: 489px;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #d8dbe1;
    width: 2px;
    transform: scaleX(0.5);
    transition: all 0.3s ease;
    border-radius: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      background-color: #a4aab6;
    }
  }

  &[aria-selected='true'] {
    &:before {
      transform: scaleX(1);
      background-color: ${(props) => props.theme.colors.primary.default};
    }
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: ${(props) => props.theme.font.family.heading};
    font-weight: 700;
    color: ${(props) => props.theme.colors.grey.dark};
  }

  ${(props) =>
    props.$singleLine &&
    !props.$keepMultiLineStyles &&
    css`
      h3 {
        font-size: 25px;
        line-height: 32px;
        @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    `};

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: ${(props) => props.theme.colors.grey[800]};
    margin-top: 16px;
  }

  ${(props) =>
    props.$hideDescriptionInMobile &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        &:not(.react-tabs__tab--selected) {
          p {
            display: none;
          }
        }
      }
    `};
`;

export default CustomTab;

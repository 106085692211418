import styled, { css } from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  position: relative;

  > div {
    max-width: 50%;
    flex-basis: 50%;
  }

  ${(props) =>
    props.$left &&
    css`
      flex-direction: row-reverse;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    min-height: ${(props) => (props.$small ? '475' : '525')}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
    > div {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;

export default Box;
